import React from "react"
import Layout from "../components/layout" 
import Seo from "../components/seo"
import { Disclosure } from '@headlessui/react'
import { MinusSmallIcon, PlusSmallIcon } from '@heroicons/react/24/outline'

const Support = () => {
  const faqs = [
    {
      question: 'How quickly can I get help on Game On!?',
      answer:
        'It typically takes 48 hours to have your first session after you have been matched to a therapist.',
    },
    {
      question: 'Do I have to be an athlete or fitness person to use Game On!?',
      answer:
        'While we cater primarily to athletes and fitness enthusiasts, we believe that everyone deserves quality mental wellness services, so we are open to helping individuals from all walks of life.',
    },
    {
      question: 'Can I use Game On! for free?',
      answer:
        'Registering on Game On! is free, but we require a subscription fee before you can book appointments with our mental health professionals.',
    },
    {
      question: 'Does Game On! have a free trial?',
      answer:
        'You get one free session when you sign up on Game On!.',
    },
    ]
    return (
      <Layout>
        <Seo title="I Am An Athlete" />
        <div className="py-10 px-5 lg:px-0">
          <div className="container mx-auto">

            <div className="text-center">
                <h1 className="text-3xl font-bold mb-2 text-gameOnDarkGreen">
                Support & Contact
                </h1>
            </div>
            
            <div className=" my-10 bg-gameOnLightGreen rounded-3xl ">
              <div className="mx-auto max-w-7xl px-6 py-24 sm:pt-32 lg:px-8 lg:py-40">
                <div className="lg:grid lg:grid-cols-12 lg:gap-8">
                  <div className="lg:col-span-5">
                    <h2 className="text-2xl font-bold leading-10 tracking-tight text-gray-900">Frequently asked questions</h2>
                    <p className="mt-4 text-base leading-7 text-gray-600">
                      Can't find the answer you're looking for? Reach out to our{' '}
                      <a href="#" className="font-semibold text-gameOnDarkGreen hover:text-gameOnDarkGreenActive">
                        customer support
                      </a>{' '}
                      team.
                    </p>
                  </div>
                  <div className="lg:col-span-7 lg:mt-0">
                  <dl className="space-y-6 divide-y divide-gray-900/10">
                    {faqs.map((faq) => (
                      <Disclosure as="div" key={faq.question} className="pt-6">
                        {({ open }) => (
                          <>
                            <dt>
                              <Disclosure.Button className="flex w-full items-start justify-between text-left text-gray-900">
                                <span className="text-base font-semibold leading-7">{faq.question}</span>
                                <span className="ml-6 flex h-7 items-center">
                                  {open ? (
                                    <MinusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                  ) : (
                                    <PlusSmallIcon className="h-6 w-6" aria-hidden="true" />
                                  )}
                                </span>
                              </Disclosure.Button>
                            </dt>
                            <Disclosure.Panel as="dd" className="mt-2 pr-12">
                              <p className="text-base leading-7 text-gray-600">{faq.answer}</p>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    ))}
                  </dl>
                  </div>
                </div>
              </div>
            </div>

            <div className="text-left my-10 bg-gameOnDarkGreen rounded-3xl py-10 px-5 lg:p-14 text-white">
              <h2 className="text-3xl font-bold mb-5">
                Contact Information
              </h2>
              <div className="grid grid-cols-1 lg:grid-cols-4 gap-4 lg:py-10">
                <div className="">
                  <h3 className="font-semibold lg:mb-3">Call Or Message Us</h3>
                  <ul>
                    <li>(979) GameOn1</li>
                    <li>(979) 426-3661</li>
                  </ul>
                </div>
                <div className="">
                  <ul>
                    <li className="mb-8">
                      <h3 className="font-semibold lg:mb-3">Support</h3>
                      <p>prinquiries@thegameonapp.com</p>
                    </li>
                    <li className="">
                      <h3 className="font-semibold lg:mb-3">Marketing</h3>
                      <p>marketing@thegameonapp.com</p>
                    </li>
                  </ul>
                </div>
                <div className="">
                  <ul>
                    <li className="mb-8">
                      <h3 className="font-semibold lg:mb-3">Accounting</h3>
                      <p>accounting@thegameonapp.com</p>
                    </li>
                    <li>
                      <h3 className="font-semibold lg:mb-3">Inquiries</h3>
                      <p>prinquiries@thegameonapp.com</p>
                    </li>
                  </ul>
                </div>
                <div className="">
                    <h3 className="font-semibold lg:mb-3">Info</h3>
                    <p>info@thegameonapp.com</p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </Layout>
    )
  }
  
  export default Support